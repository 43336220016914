<template lang="pug">
    .change-photos.-choose-photo
        ._photo
            ._photo-group(
                :class="{ 'change-photos__photo-group_have-photo': friendAnfas.photo, 'change-photos__photo-group_valid': friendAnfas.photo && friendAnfas.valid && !friendAnfas.loading, 'change-photos__photo-group_invalid': !friendAnfas.valid && !friendAnfas.loading }"
            )
                img._visible-photo(:src="friendAnfas.photo", v-if="friendAnfas.photo")
                label._photo-zone(
                    for="friendAnfas",
                    ref="friendAnfasPhoto",
                    :class="{ 'change-photos__photo-zone__dragging': isDraggingPhoto }"
                )
                    input#friendAnfas._photo-input(
                        type="file",
                        accept="image/*",
                        @drop="dropHandler($event, 'friendAnfasPhoto')",
                        @change="uploadFriendPhoto"
                    )
                    ._choose-photo-content
                        svg-icon.change-photos__choose-photo-icon(icon-name="upload-photo")
                        ._choose-photo-title(v-if="!friendAnfas.photo")
                            template(v-if="!isDraggingPhoto") Фото в анфас
                            template(v-if="isDraggingPhoto") Перетащить фото в анфас сюда
                            ._choose-photo-arrow-box
                                svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                    ._choose-photo.-valid(v-if="friendAnfas.photo && friendAnfas.valid")
                        template(v-if="!isDraggingPhoto") Загрузить другое
                        template(v-if="isDraggingPhoto") Перетащить другое
                        br/ фото в анфас
                        template(v-if="isDraggingPhoto") &nbsp;сюда
                        svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                    ._choose-photo.-invalid(v-else-if="!friendAnfas.valid") Не подошло, замените
                    ._choose-photo-loading(v-else-if="friendAnfas.loading")
                        Loading
                    ._photo-zone-decor.-top.-left
                    ._photo-zone-decor.-top.-right
                    ._photo-zone-decor.-bottom.-left
                    ._photo-zone-decor.-bottom.-right

            ._photo-group(
                :class="{ 'change-photos__photo-group_have-photo': friendProfile.photo, 'change-photos__photo-group_valid': friendProfile.photo && friendProfile.valid && !friendProfile.loading, 'change-photos__photo-group_invalid': !friendProfile.valid && !friendProfile.loading }"
            )
                img._visible-photo(
                    :src="friendProfile.photo",
                    v-if="friendProfile.photo"
                )
                label._photo-zone(
                    for="friendProfile",
                    ref="fiendProfilePhoto",
                    :class="{ 'change-photos__photo-zone__dragging': isDraggingPhoto }"
                )
                    input#friendProfile._photo-input(
                        type="file",
                        accept="image/*",
                        @drop="dropHandler($event, 'fiendProfilePhoto')",
                        @change="uploadFriendPhoto"
                    )
                    ._choose-photo-content
                        svg-icon.change-photos__choose-photo-icon(icon-name="profile")
                        ._choose-photo-title(v-if="!friendProfile.photo")
                            template(v-if="!isDraggingPhoto") Фото в профиль
                            template(v-if="isDraggingPhoto") Перетащить фото в профиль сюда
                            ._choose-photo-arrow-box
                                svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                    ._choose-photo.-valid(v-if="friendProfile.photo && friendProfile.valid")
                        template(v-if="!isDraggingPhoto") Загрузить другое
                        template(v-if="isDraggingPhoto") Перетащить другое
                        br/ фото в профиль
                        template(v-if="isDraggingPhoto") &nbsp;сюда
                        svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                    ._choose-photo.-invalid(v-else-if="!friendProfile.valid") Не подошло, замените
                    ._choose-photo-loading(v-else-if="friendProfile.loading")
                        Loading
                    ._photo-zone-decor.-top.-left
                    ._photo-zone-decor.-top.-right
                    ._photo-zone-decor.-bottom.-left
                    ._photo-zone-decor.-bottom.-right
        ._footer
            ui-button(:text="locale('analyze')" @click.native="getCompare" :disabled="!(!!friendAnfas.photo && !!friendProfile.photo)")
        ._footer
            ._example-link.-friends(@click="showExampleStatus = true") Пример эталонных фото
        photo-examples._photo-examples(
            v-if="showExampleStatus",
            @closeExamples="showExampleStatus = false"
        )
</template>

<script>
import { photoUploadMixins } from "../../../../mixins/photoUploadMixins";
import PhotoExamples from "../../photo-examples/photo-examples";
import { compareMixins } from "../../../../mixins/compare.mixins";
import dragAndDropMixin from "../../../../mixins/dragAndDrop.mixin";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "ChooseFriendPhotos",
    components: { UiButton, PhotoExamples },
    mixins: [photoUploadMixins, compareMixins, dragAndDropMixin],
    props: {
        type: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data: () => ({
        count: 0,
        friendAnfas: {
            photo: "",
            md5: "",
            valid: true,
            loading: false,
        },
        friendProfile: {
            photo: "",
            md5: "",
            valid: true,
            loading: false,
        },
        types: {
            COMPATIBILITY: "Совместимость",
            SIMILARITY: "Похожесть",
            BUSINESS: "Бизнес-партнерство",
        },
        taskInterval: "",
        showExampleStatus: false,
        activeType: null,
        personName: "с загруженных фотографий",
    }),
    computed: {
        photosReady: (state) =>
            state.friendAnfas.photo & state.friendProfile.photo,
    },
    watch: {
        "friendAnfas.photo"() {
            this.getCompare();
        },
        "friendProfile.photo"() {
            this.getCompare();
        },
        showExampleStatus(status) {
            this.$store.dispatch("set", {
                name: "globalOverlay",
                value: status,
            });
        },
    },
    created() {
        this.$bus.$on("buyCompareWithPhoto", () => {
            this.buyCompareWithPhoto();
        });
    },
    methods: {
        dropHandler(event, ref) {
            event.preventDefault();
            event.target.files = event.dataTransfer.files;
            this.uploadFriendPhoto(event);

            this.$refs[ref].classList.remove(
                "change-photos__photo-zone__dragging",
            );
        },
        async uploadFriendPhoto({ target }) {
            await this.uploadPhoto({ target });
        },
        async getCompare() {
            // Если нет авторизации - говорим об этом
            if (!this.isAuth()) return;

            // Если нет хотя бы одной фотографии - говорим об этом
            if (!this.myPhotos.profile || !this.myPhotos.anfas) {
                this.showUploadPhotosAttempt();
                return;
            }

            if (!this.friendAnfas.photo || !this.friendProfile.photo) {
                return;
            }

            this.activeType = this.type;

            await this.getCompareWithPhotosResult(
                this.friendAnfas.photo,
                this.friendAnfas.md5,
                this.friendProfile.photo,
                this.friendProfile.md5,
                this.type.id,
            )
                .then((r) => {
                    const result = r.data.compareWithPhotosGet,
                        percent = result.resultValue,
                        title = result.resultTitle,
                        desc = result.resultDescription;

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: this.type,
                            photo: this.friendAnfas.photo,
                            personUuid: "",
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                    this.$bus.$emit("backToPersonDescription");
                })
                .catch((e) => {
                    if (e.graphQLErrors[0].extensions.code === 1002) {
                        this.offerToBuyCompare(this.type.id, true);
                        return;
                    }
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                });
        },
        async checkCompareStatus() {
            console.log("checkCompareStatus");
            if (this.friendAnfas.photo && this.friendProfile.photo) {
                console.log(
                    "checkCompareStatus success photos",
                    this.friendAnfas,
                    this.friendProfile,
                );
                await this.$store
                    .dispatch("person/checkPhotoCompareStatus", {
                        apolloClient: this.$apollo,
                        anfas: this.friendAnfas.photo,
                        anfasMd5: this.friendAnfas.md5,
                        profile: this.friendProfile.photo,
                        profileMd5: this.friendProfile.md5,
                        type: this.type.id,
                    })
                    .then((r) => {
                        console.log("checkPhotoCompareStatus success", r);
                        if (r === 1002) {
                            this.getComparePrice();
                        } else {
                            // Здесь нужно вызвать результат сравнения
                            this.$bus.$emit(
                                "showCompareResult",
                                r.resultValue,
                                r.resultTitle,
                                r.resultDescription,
                                this.type.id,
                            );
                        }
                    })
                    .catch((e) => {
                        console.log("checkPhotoCompareStatus success", { e });
                    });
            } else {
                console.log(
                    "checkCompareStatus no photos",
                    this.friendAnfas,
                    this.friendProfile,
                );
            }
        },
        async getComparePrice() {
            await this.$store
                .dispatch("person/getComparePrice", {
                    apolloClient: this.$apollo,
                    type: this.type.id,
                })
                .then((r) => {
                    this.$bus.$emit(
                        "confirmBuyCompareWithPhoto",
                        this.types[this.type.id],
                        r.data.comparePrice,
                    );
                });
        },
        async buyCompareWithPhoto() {
            await this.$store
                .dispatch("person/buyPhotoCompare", {
                    apolloClient: this.$apollo,
                    anfas: this.friendAnfas.photo,
                    anfasMd5: this.friendAnfas.md5,
                    profile: this.friendProfile.photo,
                    profileMd5: this.friendProfile.md5,
                    type: this.type.id,
                })
                .then((r) => {
                    const taskId = r.data.compareWithPhotosCreate;
                    // делаем первый запрос к статусу таска через 2 сек, потому что за это время таск уже мог отработать
                    setTimeout(() => {
                        this.checkTask(taskId);
                    }, 2000);
                })
                .catch((e) => {
                    const errorCode = e.graphQLErrors[0].extensions.code;
                    let title, text;

                    switch (errorCode) {
                        case 1002:
                            title = "attention";
                            text = "retry";
                            break;
                        case 1003:
                            title = "noAccess";
                            text = "noAccessText";
                            break;
                        default:
                            title = "unknownError";
                            text = "retry";
                    }
                    this.$msgBox(
                        this.localeError(title),
                        this.localeError(text),
                    );
                });
        },
    },
};
</script>

<style lang="scss">
@import "./choose-friend-photos.scss";
@import "../../change-photos/change-photos";
</style>
