<template lang="pug">
    .person-description
        //loading(v-if="!categories.length")
        ._head
            ._back(@click="back()" v-if="activeSection >= 0")
                svg-icon(icon-name="arrow-left")._back-icon
                ._back-text {{ locale("back") }}
            ._subtitle(:class="{'without-back-btn': activeSection < 0}") {{ profession ? profession : subtitle }}
        ._profession-name(v-for="(category, index) in currentData" :key="index" ref="name" @click="chooseProfessionCategory(category)" :class="{disabled: category.disabled}" v-if="subtitle === 'Профессии' && professionOrientationCompleted") {{ category.name }}
        svg(data-tip="sphere" x="0px" y="0px" viewBox="0 0 662.1 605" style="enable-background:new 0 0 662.1 605;" xml:space="preserve" v-if="subtitle === 'Профессии' && professionOrientationCompleted && showBlades")._profession-illustration
            g._blade(v-for="(item, index) in currentData" :key="item.name" :style="`transform: rotateZ(${360 / currentDataLength * index}deg);}`" @click="chooseProfessionCategory(item)")
                path._blade-line(d="M108.5,133c0,0,22.9,24.8,60.3,48.6c30.4,19.3,53.1,55.4,93.6,66.7")
                path._blade-line(d="M108.5,133c0,0,9.3,12,39.1,39.2c24.9,22.7,74.3,64.8,114.8,76.1")
                path._blade-line(d="M108.5,133c0,0,24.1,12.8,61.5,36.6c30.4,19.3,52,67.4,92.5,78.7")
                path._blade-line(d="M108.5,133c0,0,77.3,19,73.2,44.8c-5,31,38.3,59.4,80.7,70.5")
                path._blade-line(d="M108.5,133c0,0,14.1,2.2,54.2,27.3c42.8,26.8,54.6,70.7,99.7,88")
                path._blade-line(d="M108.5,133c0,0,30.1,20.4,60.3,48.6c34.4,32.1,53.1,55.4,93.6,66.7")
                path._blade-line(d="M108.5,133c0,0,32.7,25.1,66.7,33.5c32.2,7.9,52.2,66.6,87.2,81.8")
                path._blade-line(d="M108.5,133c0,0,2.9,3.5,43.1,28.7c42.8,26.8,70.3,75.3,110.8,86.6")
                path._blade-line(d="M108.5,133c0,0,43.7,16,83.8,41.1c42.8,26.8,29.6,62.9,70.1,74.2")
                path._blade-line(d="M108.5,133c0,0,12,20,56,36.6c50.3,19,62.8,63.5,97.9,78.7")
                ellipse._blade-dot(transform="matrix(0.6235 -0.7818 0.7818 0.6235 -62.6971 134.9862)" cx="108.8" cy="132.6" rx="8" ry="8" :data-deg="360 / currentDataLength * index" ref="name-position")
                ellipse._blade-dot.-for-circle(transform="matrix(0.6235 -0.7818 0.7818 0.6235 -96.0124 297.8574)" cx="261.2" cy="248.6" rx="6" ry="6")
        svg(viewBox="-205 -205 410 410" data-tip="sphere" v-else)._illustration
            // Главный раздел, первый шаг выбора
            g(v-for="(sectionPath, sectionIndex) in sectionPaths" :key="sectionIndex" v-if="activeSection < 0" @click="activeSection = sectionIndex")._pie-group
                path(:d="sectionPath.d")._pie-section
                svg(width="60px" height="50px" :x="sectionPath.cx" :y="sectionPath.cy")
                    foreignObject(width="100%" height="30")
                        p._text {{ categories[sectionIndex].name }}
                    circle(v-for="(subcategory, index) in categories[sectionIndex].subcategories" r="1.5" :cx="(index === 0) ? 2 : (index + 0.3) * 6" cy="25" :class="{active: subcategory.unlocked}")._subcategory-indicator

            // Категории в выбранном разделе
            g(v-for="(categoryPath, categoryIndex) in categoriesPaths(activeSection)" :key="categoryIndex" v-if="activeSection >= 0 && activeCategory < 0" @click="buyCategory(categoryIndex)" :class="{active: categories[activeSection].subcategories[categoryIndex].unlocked}")._pie-group
                path(:d="categoryPath.d")._pie-section
                svg(width="50px" height="50px" :x="categoryPath.cx" :y="categoryPath.cy")
                    foreignObject(width="100%" height="30")
                        p._text(:class="{large: categories[activeSection].id == 4}") {{ categories[activeSection].subcategories[categoryIndex].name }}
                    circle(v-for="(i, index) in categories[activeSection].subcategories[categoryIndex].itemsTotal" r="1" :cx="(index === 0) ? 2 : (index + 0.5) * 4" cy="25" v-if="categories[activeSection].subcategories[categoryIndex].itemsTotal > 1")._subcategory-indicator

            // Описание внутри выбранной категории
            g(v-for="(subcategoryPath, subcategoryIndex) in subcategoriesPaths(activeCategory)" :key="subcategoryIndex" v-if="activeCategory >= 0" @click="showDescription(subcategoryIndex)")._pie-group.active
                path(:d="subcategoryPath.d")._pie-section
                svg(width="40px" height="50px" :x="subcategoryPath.cx" :y="subcategoryPath.cy")
                    foreignObject(width="100%" height="30")
                        p(:class="subcategoryPath.text.size" :style="{'text-align': subcategoryPath.text.position}")._text {{ categories[activeSection].subcategories[activeCategory].items[subcategoryIndex].name }}
        ._merlin-box(:class="{professions: subtitle === 'Профессии' && professionOrientationCompleted}")
            ._merlin-inner
                vue-lottie-player(autoplay controls loop mode="normal" :animationData="analyzeFile")._player
</template>

<script>
import { descriptionMixins } from "../../../mixins/descriptionMixins";
import VueLottiePlayer from "vue-lottie-player";
import gql from "graphql-tag";
import onBoardingTypeRewardQuery from "@/graphql/queries/onBoardingTypeReward.query.graphql";

const CIRCLE_START_POSITION = Math.PI;

export default {
    name: "PersonDescription",
    components: { VueLottiePlayer },
    mixins: [descriptionMixins],
    data: () => ({
        activeSection: -1,
        activeCategory: -1,
        gap: 5,
        outerRadius: 105,
        innerRadius: 10,
        innerDecor: false,
        categoryInfo: {
            name: null,
            price: null,
            category: null,
            subCategory: null,
        },
        analyzeFile: require("../../../assets/lottie-files/analyze.json"),
        professionCategories: [],
        profession: null,
        showBlades: true,
    }),
    computed: {
        personUuid: (state) => state.$store.getters["person/uuid"],
        categories: (state) => state.$store.getters["person/personCategories"],
        sectionPaths() {
            const step = (2 * Math.PI) / this.categories.length;
            return this.getPaths(this.categories, step);
        },
        subtitle: (state) => {
            return state.activeCategory >= 0
                ? state.categories[state.activeSection].subcategories[
                    state.activeCategory
                ].name
                : state.activeSection >= 0
                    ? state.categories[state.activeSection].name
                    : state.locale("choose-type");
        },
        avatar: (state) => state.$store.getters["person/photos"]["avatar"],
        windowWidth: (state) => state.$store.getters["windowWidth"],
        planIds: (state) => state.$store.getters["planIds"],
        professionOrientationCompleted: (state) =>
            state.userOnBoardingTypesFromMixin.find(
                (t) => t.type === "PROFESSION_ORIENTATION" && t.passed,
            )?.id || false,
        currentData: function () {
            if (this.profession) {
                return this.professionCategories.find(
                    (cat) => cat.name === this.profession,
                )?.professions;
            }
            return this.professionCategories;
        },
        currentDataLength: (state) => state.currentData.length,
    },
    watch: {
        async personUuid(val) {
            await this.personGetDescription(val);
        },
        "$route.params.uuid"() {
            this.activeSection = -1;
        },
        activeSection() {
            this.showTips("inside-sphere");
            this.$emit("changeCategory", this.activeSection);
            this.$store.dispatch("setDescriptionSectionId", this.activeSection);
        },
        subtitle(val) {
            if (val === "Профессии" && this.professionOrientationCompleted) {
                this.$emit("change-decoration-type", "analyze");
                this.$nextTick(() => {
                    this.formatTitles();
                });
            } else {
                this.$emit("change-decoration-type", "person");
            }
        },
        profession() {
            setTimeout(() => {
                this.formatTitles();
            });
        },
    },
    async created() {
        if (this.professionOrientationCompleted) {
            await this.getProfessions();
            window.addEventListener("resize", async () => {
                this.formatTitles();
            });
        }
        this.$bus.$on("confirmDialogAction", (type) => {
            if (type === "personDescriptionSubscribeAttempt") {
                this.$modal.show("subscription", {
                    categoryId: this.planIds[this.subtitle],
                });
                this.$store.dispatch("changeConfirmDialog", {
                    show: false,
                });
            }
        });
        this.$bus.$on("confirmDialogAction", (type) => {
            if (type === "showSubscriptionsModalForPersonDescription") {
                this.$modal.show("subscription");
                this.$store.dispatch("changeConfirmDialog", {
                    show: false,
                });
            }
        });
    },
    async mounted() {
        await this.personGetDescription(this.$store.getters["person/uuid"]);
        if (localStorage.want) {
            this.activeSection = Number(localStorage.want);
            localStorage.removeItem("want");
        }
        if (localStorage.wantCategory) {
            this.activeCategory = Number(localStorage.wantCategory);
            localStorage.removeItem("wantCategory");
        }
        setTimeout(() => {
            this.innerDecor = true;
        }, 2000);
    },
    destroyed() {
        this.$store.dispatch("setDescriptionSectionId", -1);
        this.$bus.$off("confirmDialogAction");
        window.removeEventListener("resize", async () => {
            this.formatTitles();
        });
    },
    methods: {
        getPaths(cats, step) {
            return cats.map((_, i) => {
                return this.pie(
                    CIRCLE_START_POSITION + step * i,
                    step,
                    this.outerRadius,
                    this.innerRadius,
                    this.gap,
                );
            });
        },
        categoriesPaths(catId) {
            if (catId >= 0) {
                const category = this.categories[catId];
                const step = (2 * Math.PI) / category.subcategories.length;
                return this.getPaths(category.subcategories, step);
            }
        },
        subcategoriesPaths(catId) {
            if (catId >= 0) {
                const category =
                    this.categories[this.activeSection].subcategories[catId];
                const step = (2 * Math.PI) / category.itemsTotal;
                let fakeArray = [];
                for (let i = 1; i <= category.itemsTotal; i++) {
                    fakeArray.push({});
                }
                return this.getPaths(fakeArray, step);
            }
        },
        pie(from, angle, outerRadius, innerRadius, gap = 0) {
            const or = outerRadius;
            const ir = innerRadius;
            let d;

            if (angle > Math.PI) {
                const x0 = or * Math.cos(from);
                const y0 = or * Math.sin(from);
                d = `M ${x0} ${y0} `;

                const x1 = or * Math.cos(from + Math.PI);
                const y1 = or * Math.sin(from + Math.PI);
                d += `A ${or} ${or} 0 0 1 ${x1} ${y1} `;
                d += `A ${or} ${or} 0 0 1 ${x0} ${y0} `;

                const x2 = ir * Math.cos(from);
                const y2 = ir * Math.sin(from);
                d += `M ${x2} ${y2} `;

                const x4 = ir * Math.cos(from + Math.PI);
                const y4 = ir * Math.sin(from + Math.PI);
                d += `A ${ir} ${ir} 0 0 0 ${x4} ${y4} `;

                d += `A ${ir} ${ir} 0 0 0 ${x2} ${y2} Z`;
            } else {
                const og = Math.asin(gap / 2 / outerRadius);
                const ig = Math.asin(gap / 2 / innerRadius);

                const x0 = or * Math.cos(from + og);
                const y0 = or * Math.sin(from + og);
                d = `M ${x0} ${y0} `;

                const x2 = or * Math.cos(from + angle - og);
                const y2 = or * Math.sin(from + angle - og);
                d += `A ${or} ${or} 0 0 1 ${x2} ${y2} `;

                const x3 = ir * Math.cos(from + angle - ig);
                const y3 = ir * Math.sin(from + angle - ig);
                d += `L ${x3} ${y3} `;

                const x4 = ir * Math.cos(from + ig);
                const y4 = ir * Math.sin(from + ig);
                d += `A ${ir} ${ir} 0 0 0 ${x4} ${y4} `;
                d += `L ${x0} ${y0} Z`;
            }

            const mid = from + angle / 2;

            let different = 0,
                text = {
                    size: "",
                    position: "",
                };
            if (this.activeSection >= 0 && this.activeCategory >= 0) {
                const descCount =
                    this.categories[this.activeSection].subcategories[
                        this.activeCategory
                    ].itemsTotal;
                if (descCount === 2) {
                    different = 15;
                    text.size = "large";
                    text.position = "center";
                }
                if (descCount >= 5) {
                    text.size = "small";
                }
                if (descCount > 6) {
                    different = -10;
                }
            }

            return {
                d,
                text,
                cx:
                    (or + ir) * 0.65 * Math.cos(mid) +
                    angle * ir -
                    35 -
                    different,
                cy:
                    (or + ir) * 0.65 * Math.sin(mid) +
                    angle * ir -
                    30 -
                    different,
            };
        },
        buyCategory(catId) {
            if (
                this.categories[this.activeSection].subcategories[catId]
                    .name === "Профессии" && !this.professionOrientationCompleted
            ) {
                this.confirmOnBoardingSwitch("PROFESSION_ORIENTATION");
                return;
            }
            const category =
                this.categories[this.activeSection].subcategories[catId];
            if (category.unlocked) {
                if (category.itemsTotal > 1) {
                    this.activeCategory = catId;
                } else {
                    this.prependShowModal(this.activeSection, catId, 0);
                }
            } else {
                this.$store.dispatch("changeConfirmDialog", {
                    show: true,
                    loading: false,
                    title: this.locale("need-subscribe"),
                    text: this.locale("need-subscribe-text", {category: this.subtitle}),
                    price: 0,
                    comparisonCount: "",
                    buttons: true,
                    confirmText: this.locale("btn"),
                    type: "personDescriptionSubscribeAttempt",
                });
            }
        },
        showDescription(descId) {
            this.prependShowModal(
                this.activeSection,
                this.activeCategory,
                descId,
            );
        },
        back() {
            if (this.profession) {
                this.profession = null;
                return;
            }
            if (this.activeCategory >= 0) {
                this.activeCategory = -1;
                return;
            }
            if (this.activeSection >= 0) {
                this.activeSection = -1;
            }
        },
        async getProfessions() {
            await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingTypeRewardQuery}
                    `,
                    variables: {
                        step: 11,
                    },
                })
                .then((r) => {
                    const result = [];
                    const stats = r.data.onBoardingTypeReward;
                    console.log("stats", stats);
                    Object.keys(stats).map((categoryName) => {
                        const professions = [];
                        let sum = 0;
                        Object.keys(stats[categoryName]).forEach(
                            (professionName) => {
                                professions.push({
                                    name: professionName,
                                    value: stats[categoryName][professionName],
                                    disabled: false,
                                });
                                sum += stats[categoryName][professionName];
                            },
                        );

                        result.push({
                            name: categoryName,
                            professions: professions
                                .sort((a, b) => (a.value < b.value ? 1 : -1))
                                .slice(0, 3),
                            average: sum,
                            sum,
                            disabled: false,
                        });
                    });
                    this.isActive = true;

                    this.professionCategories = result
                        .sort((a, b) => (a.average < b.average ? 1 : -1))
                        .slice(0, 3);
                })
                .catch((e) => {
                    console.log("stats", e);
                    this.showBlades = false;
                });
        },
        chooseProfessionCategory(category) {
            if (!this.profession) {
                this.profession = category.name;
            } else {
                this.showProfessionReferralModal(category.name);
            }
        },
        formatTitles() {
            const positions = this.$refs["name-position"];
            const boxPosition = document
                .querySelector(".choose-want")
                .getBoundingClientRect();

            if (positions === undefined) return;

            positions.forEach((position, index) => {
                const textPosition = position.getBoundingClientRect();
                const text = this.$refs.name[index];
                text.style.left = `${
                    textPosition.left -
                    text.clientWidth / 2 +
                    10 -
                    boxPosition.left
                }px`;

                let kf = -25;
                let bKf = 5;

                if (this.pageWidth <= 991) {
                    kf = 50;
                    bKf = 70;
                }

                if (Number(position.getAttribute("data-deg")) > 140) {
                    text.style.top = `${
                        textPosition.bottom + bKf - boxPosition.top
                    }px`;
                } else {
                    if (text.clientHeight > 50) {
                        text.style.top = `${
                            textPosition.top +
                            kf -
                            boxPosition.top -
                            (text.clientHeight * 0.8)
                        }px`;
                    } else if (text.clientHeight > 30) {
                        text.style.top = `${
                            textPosition.top +
                            kf -
                            boxPosition.top -
                            text.clientHeight / 2
                        }px`;
                    } else {
                        text.style.top = `${
                            textPosition.top + kf - boxPosition.top
                        }px`;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "person-description";
</style>
