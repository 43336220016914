<template lang="pug">
    .choose-want(:class="`choose-want_`+type")
        ._wrap#tilt
            // Круги с вертикальными линиями
            vue-lottie-player(autoplay controls loop mode="normal" :animationData="lottie.bigCircle")._circle.-big

            transition(@after-enter="bigCircleAfterEnter" appear)
                svg(viewBox="0 0 880 880"  :class="bigCircleClasses")._circle.-big
                    circle(r="430" cx="440" cy="440").rotate
            transition(@after-enter="mediumCircleAfterEnter" appear)
                svg(viewBox="0 0 880 880" :class="mediumCircleClasses")._circle.-medium
                    circle(r="430" cx="440" cy="440").rotate
            transition(@after-enter="smallCircleAfterEnter" appear)
                svg(viewBox="0 0 880 880" :class="smallCircleClasses")._circle.-small
                    circle(r="400" cx="440" cy="440").rotate

            transition(@after-enter="outerWaveAfterEnter" appear)
                svg(viewBox="0 0 855 855" :class="outerWaveClasses")._outer-wave
                    path.rotate#outerWave(d="M124.67,237.35c43.12-40.73,23.5-88.57,60.13-108.63c17.63-9.66,22.81,1.09,54.57-10.11c32.16-11.33,32.06-24.19,58.11-35.37C337.83,65.92,351.94,90.84,408,78c38.22-8.76,42.27-22.77,66.82-19.01c25.69,3.94,27.6,20.26,62.65,34.36c35.63,14.33,42.44,0.98,68.72,14.15c29.83,14.94,24,33.6,63.16,70.74c35.79,33.95,48.43,25.72,61.64,50.02c17.08,31.41-1.42,49.99,17.68,86.4c14.76,28.13,28.88,22.93,36.38,45.47c10.47,31.49-14.09,50.69-17.18,99.03c-2.11,33.11,14.48,38.29,9.09,63.66c-5.14,24.24-19.4,29.63-38.4,56.08c-36.76,51.19-14.38,74.2-45.47,108.13c-24.6,26.85-36.23,9.84-91.96,47.49c-36.86,24.91-45.38,41.54-73.77,45.47c-26.35,3.65-28.47-9.37-59.12-9.09c-50.27,0.46-64.59,35.68-100.04,27.28c-21.57-5.11-18.54-18.69-48.51-29.31c-29.82-10.57-38.47,0.88-62.65-8.59c-26.03-10.19-25.23-27.07-56.34-54.06c-35.79-31.05-47.17-17.68-69.73-40.93c-30.59-31.53-9.22-55.7-40.93-128.84c-18.08-41.71-31.45-48.67-30.32-75.79c1.1-26.19,14.32-37.31,16.67-68.97c2-26.82-10.15-29.84-9.85-59.87c0.11-10.66,0.6-34.26,13.64-56.08C93.06,254.25,105.62,255.35,124.67,237.35z")


            svg(viewBox="0 0 855 855" :class="{'active': innerFillWave}")._inner-line-wave
                circle(r="260" cx="427" cy="427")._inner-line-wave-lines

            svg(viewBox="0 0 855 855" :class="{'active': innerWave}")._inner-wave.-first
                path(d="M240 270l4-18 16-7 7-18 18 2 7-13c11 2 8 2 16-6h7l5-9 16 3 6-7 10 3 5-9 10 4 10-11 14 5 13-11 14 13 11-8 16 6 16-11 10 14 15-5 5 7 10-1 5 6 11-3 9 13 10-2 2 9 13-4 4 13 15-2 6 16 18 1 4 17 18 11 4 19 15 7-3 11 8 5v8l9 5-2 15 9 7-6 8 12 7-5 11 12 9-8 13 14 14-13 11 7 17-5 13 11 16-13 12 3 15-8 5 1 9-5 6 3 8-11 9v12l-8 3 2 12-12 3 1 14-14 9v18l-17 5-7 20-23-2-7 17h-14c-6 6-4 6-12 8l-6 8-15-4-4 7-11-7-2 14-11-5-10 11-15-6-13 11-13-13-12 11-17-5-14 8-13-11-13 4-6-7c-8-1-8-1-15-5l-9 2-9-11-11 1-3-7-13 2-3-10h-17l-5-14-18-3-2-16-18-12-5-21-13-6-1-10-6-7-1-6-9-7v-15l-2-7 1-9-9-4 2-13-8-8 5-17-6-10 6-15-7-15 6-14-9-13 15-15-6-10 8-13-2-2 7-9-4-8 9-5 1-12 9-5-1-11 8-5v-20l16-8z")

            svg(viewBox="0 0 855 855" :class="{'active': innerWave}")._inner-wave.-second
                path(d="M240 270l4-18 16-7 7-18 18 2 7-13c11 2 8 2 16-6h7l5-9 16 3 6-7 10 3 5-9 10 4 10-11 14 5 13-11 14 13 11-8 16 6 16-11 10 14 15-5 5 7 10-1 5 6 11-3 9 13 10-2 2 9 13-4 4 13 15-2 6 16 18 1 4 17 18 11 4 19 15 7-3 11 8 5v8l9 5-2 15 9 7-6 8 12 7-5 11 12 9-8 13 14 14-13 11 7 17-5 13 11 16-13 12 3 15-8 5 1 9-5 6 3 8-11 9v12l-8 3 2 12-12 3 1 14-14 9v18l-17 5-7 20-23-2-7 17h-14c-6 6-4 6-12 8l-6 8-15-4-4 7-11-7-2 14-11-5-10 11-15-6-13 11-13-13-12 11-17-5-14 8-13-11-13 4-6-7c-8-1-8-1-15-5l-9 2-9-11-11 1-3-7-13 2-3-10h-17l-5-14-18-3-2-16-18-12-5-21-13-6-1-10-6-7-1-6-9-7v-15l-2-7 1-9-9-4 2-13-8-8 5-17-6-10 6-15-7-15 6-14-9-13 15-15-6-10 8-13-2-2 7-9-4-8 9-5 1-12 9-5-1-11 8-5v-20l16-8z")

            svg(viewBox="0 0 855 855" :class="{'active': innerWave}")._inner-wave.-third
                path(d="M240 270l4-18 16-7 7-18 18 2 7-13c11 2 8 2 16-6h7l5-9 16 3 6-7 10 3 5-9 10 4 10-11 14 5 13-11 14 13 11-8 16 6 16-11 10 14 15-5 5 7 10-1 5 6 11-3 9 13 10-2 2 9 13-4 4 13 15-2 6 16 18 1 4 17 18 11 4 19 15 7-3 11 8 5v8l9 5-2 15 9 7-6 8 12 7-5 11 12 9-8 13 14 14-13 11 7 17-5 13 11 16-13 12 3 15-8 5 1 9-5 6 3 8-11 9v12l-8 3 2 12-12 3 1 14-14 9v18l-17 5-7 20-23-2-7 17h-14c-6 6-4 6-12 8l-6 8-15-4-4 7-11-7-2 14-11-5-10 11-15-6-13 11-13-13-12 11-17-5-14 8-13-11-13 4-6-7c-8-1-8-1-15-5l-9 2-9-11-11 1-3-7-13 2-3-10h-17l-5-14-18-3-2-16-18-12-5-21-13-6-1-10-6-7-1-6-9-7v-15l-2-7 1-9-9-4 2-13-8-8 5-17-6-10 6-15-7-15 6-14-9-13 15-15-6-10 8-13-2-2 7-9-4-8 9-5 1-12 9-5-1-11 8-5v-20l16-8z")

            svg(viewBox="0 0 880 880" v-if="type === 'auth'")._sections
                g._section(:class="{'active': sections}" @click="$emit('chooseWant', 0)")
                    path(d="M352.1,437.5H206c1.3-127.1,104-229.9,231-231.5v146C390.7,353.9,353.6,391.2,352.1,437.5z")._section-pie
                    text(transform="matrix(1.0091 0 0 1 298.7881 304.8164)") {{ locale("choose-1-1") }}
                    text(transform="matrix(1.0091 0 0 1 286.0869 327.106)") {{ locale("choose-1-2") }}
                    text(transform="matrix(1.0091 0 0 1 269.6421 349.3955)") {{ locale("choose-1-3") }}
                g._section(:class="{'active': sections}" @click="$emit('chooseWant', 2)")
                    path(d="M442,352V206c127.5,1.1,230.6,104.1,232,231.5h-145C527.3,390.4,489.1,352.8,442,352z")._section-pie
                    text(transform="matrix(1.0091 0 0 1 521.9028 304.8164)") {{ locale("choose-2-1") }}
                    text(transform="matrix(1.0091 0 0 1 514.7544 327.106)") {{ locale("choose-2-2") }}
                    text(transform="matrix(1.0091 0 0 1 530.5981 349.3955)") {{ locale("choose-2-3") }}
                g._section(:class="{'active': sections}" @click="$emit('chooseWant', 1)")
                    path(d="M437,528.9V674c-127-1.6-229.6-104.4-231-231.5h146C353.1,489.2,390.3,527.1,437,528.9z")._section-pie
                    text(transform="matrix(1.0091 0 0 1 272.084 508.8174)") {{ locale("choose-3-1") }}
                    text(transform="matrix(1.0091 0 0 1 289.8706 531.1069)") {{ locale("choose-3-2") }}
                    text(transform="matrix(1.0091 0 0 1 284.6577 553.3965)") {{ locale("choose-3-3") }}
                g._section(:class="{'active': sections}" @click="$emit('chooseWant', 3)")
                    path(d="M528.9,442.5h145C672.6,569.9,569.5,672.9,442,674V529C489.5,528.2,527.9,490,528.9,442.5z")._section-pie
                    text(transform="matrix(1.0091 0 0 1 527.542 508.8174)") {{ locale("choose-4-1") }}
                    text(transform="matrix(1.0091 0 0 1 530.4028 531.1069)") {{ locale("choose-4-2") }}
                    text(transform="matrix(1.0091 0 0 1 529.9507 553.3965)") {{ locale("choose-4-3") }}
</template>
<script>
//import VanillaTilt from "vanilla-tilt";
import VueLottiePlayer from "vue-lottie-player";

export default {
    name: "ChooseWant",
    components: { VueLottiePlayer },
    props: {
        type: {
            require: false,
            default: "auth",
            type: String,
        },
        outerLineWave: {
            require: true,
            default: false,
            type: Boolean,
        },
        innerFillWave: {
            require: true,
            default: false,
            type: Boolean,
        },
        innerWave: {
            require: true,
            default: false,
            type: Boolean,
        },
        sections: {
            require: true,
            default: false,
            type: Boolean,
        },
    },
    data: () => ({
        tilt: "",
        lottie: {
            bigCircle: require("./lottie/1"),
        },
        circle: {
            big: false,
            medium: false,
            small: false,
        },
        outerWave: {
            active: false,
            paths: {
                1: "M124.67,237.35c43.12-40.73,23.5-88.57,60.13-108.63c17.63-9.66,22.81,1.09,54.57-10.11c32.16-11.33,32.06-24.19,58.11-35.37C337.83,65.92,351.94,90.84,408,78c38.22-8.76,42.27-22.77,66.82-19.01c25.69,3.94,27.6,20.26,62.65,34.36c35.63,14.33,42.44,0.98,68.72,14.15c29.83,14.94,24,33.6,63.16,70.74c35.79,33.95,48.43,25.72,61.64,50.02c17.08,31.41-1.42,49.99,17.68,86.4c14.76,28.13,28.88,22.93,36.38,45.47c10.47,31.49-14.09,50.69-17.18,99.03c-2.11,33.11,14.48,38.29,9.09,63.66c-5.14,24.24-19.4,29.63-38.4,56.08c-36.76,51.19-14.38,74.2-45.47,108.13c-24.6,26.85-36.23,9.84-91.96,47.49c-36.86,24.91-45.38,41.54-73.77,45.47c-26.35,3.65-28.47-9.37-59.12-9.09c-50.27,0.46-64.59,35.68-100.04,27.28c-21.57-5.11-18.54-18.69-48.51-29.31c-29.82-10.57-38.47,0.88-62.65-8.59c-26.03-10.19-25.23-27.07-56.34-54.06c-35.79-31.05-47.17-17.68-69.73-40.93c-30.59-31.53-9.22-55.7-40.93-128.84c-18.08-41.71-31.45-48.67-30.32-75.79c1.1-26.19,14.32-37.31,16.67-68.97c2-26.82-10.15-29.84-9.85-59.87c0.11-10.66,0.6-34.26,13.64-56.08C93.06,254.25,105.62,255.35,124.67,237.35z",
                2: "M123.41,195.03c7.83-18.05,26.13-33.94,61.39-66.32c26.03-23.9,39.31-35.92,52.29-39.41c33.74-9.08,44.1,15.99,86.4,13.64C364.09,100.7,367.77,76.88,408,78c29.23,0.82,31.07,14.42,62.15,13.c36.66-0.99,44.89-18.03,68.21-12.51c28.29,6.71,24.26,33.71,57.98,53.43c37.95,22.2,61.63-1.14,87.54,20.c25.67,21.41,6.45,47.72,34.11,85.26c25.73,34.94,55.62,30.14,63.66,59.12c6.16,22.2-10.64,27.69-12.51,62.c-2.5,46.7,26.62,56.8,20.46,95.49c-4.8,30.2-23.35,29.12-31.83,65.94c-8.17,35.47,7.33,43.89,0,72.c-7.82,30.77-27.76,30.35-70.48,78.44c-45.7,51.43-39.44,70.54-68.21,81.85c-34.04,13.38-50.29-10.42-90.95,4.c-34.38,12.66-31.92,33.05-60.25,38.65c-37.61,7.44-51.1-26.69-92.08-21.6c-37.23,4.62-45.72,35.22-68.21,29.c-28.77-7.24-20.84-58.8-56.84-76.17c-29.78-14.37-53.16,12.25-75.03-3.41c-22.92-16.41-2.65-49.5-27.28-88.c-21.51-34.21-46.41-23.99-61.39-53.43c-20.12-39.55,20.7-66.08,3.41-119.37c-10.31-31.77-28.3-33.09-36.38-71.c-2.6-12.41-7.79-37.17,3.41-61.39c11.51-24.89,27.6-21.68,44.34-45.47C123.49,255.16,104.74,238.06,123.41,195.03z",
                3: "M109.77,174.57c25.9-12.62,41.07,9.04,69.35-3.41c28.91-12.72,21.18-38.94,54.57-68.21C257,82.5,291.8,68.08,325.77,67.71c29.5-0.33,39.71,10.14,65.94,2.27c23.33-7,28.42-19.23,44.34-17.c17.61,2.41,20.14,18.6,43.2,32.97c15.51,9.67,29.17,11.56,52.29,14.78c56.9,7.91,68.63-9.86,93.22,2.c26.05,12.85,15.08,33.87,47.75,67.07c33.83,34.39,57.49,23.94,76.17,54.57c12.5,20.5,2.37,25.96,15.92,71.c12.83,43.26,23.33,43.13,25.01,68.21c2.44,36.54-19.57,41-21.6,80.72c-2.34,45.96,26.34,55.79,18.19,89.c-8.06,33.64-37.61,30.3-53.43,69.35c-16.89,41.69,9.88,62.51-7.64,89.68c-19.96,30.95-58.4,9.67-110.59,44.c-42.34,28.22-39.34,57.09-70.48,62.53c-36.46,6.37-50.08-31.54-88.67-26.15c-39.62,5.53-48.27,48.71-79.58,45.c-23.09-2.39-21.71-26.21-52.29-39.79c-41.84-18.57-71.48,13.99-100.04-5.68c-21.53-14.83-7.81-35.49-31.83-64c-28.21-34.42-60.4-22.12-80.72-53.43c-13.34-20.55-1.08-28.33-9.09-75.03c-10.94-63.7-37.24-69.58-32.97-110.c3.61-34.43,23.19-37.41,22.74-73.89c-0.25-20.11-6.24-22.92-14.78-62.53c-6.48-30.08-10.18-47.26-9.09-68.C68.15,258.17,71.53,193.2,109.77,174.57z",
            },
            options: {
                morphPrecision: 15,
                easing: "ease",
                reverseSecondPath: true,
                yoyo: true,
                duration: 3000,
            },
            draw: "",
            stage_1: "",
            stage_2: "",
            stage_3: "",
        },
    }),
    computed: {
        bigCircleClasses: (state) => ({
            active: state.circle.big,
        }),
        mediumCircleClasses: (state) => ({
            active: state.circle.medium,
        }),
        smallCircleClasses: (state) => ({
            active: state.circle.medium,
        }),
        outerWaveClasses: (state) => ({
            active: state.outerWave.active,
        }),
    },
    mounted() {
        //this.tilt = document.getElementById('tilt');
        //VanillaTilt.init(this.tilt, {
        //    "full-page-listening": true,
        //    reverse: true,
        //    reset: true,
        //    max: 10
        //});
    },
    destroyed() {
        //this.tilt.vanillaTilt.destroy();
        this.loading = true;
    },
    methods: {
        bigCircleAfterEnter() {
            this.circle.big = true;
        },
        mediumCircleAfterEnter() {
            this.circle.medium = true;
        },
        smallCircleAfterEnter() {
            this.circle.small = true;
        },
        outerWaveAfterEnter() {
            this.outerWave.active = true;
        },
    },
};
</script>

<style lang="scss">
@import "choose-want";
</style>
