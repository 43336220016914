<template lang="pug">
    .compare-with-photo
        ._head
            button._back-btn(
                type="button",
                role="button",
                @click="backToPersonDescription",
                v-if="type === ''"
            )
                svg-icon._back-icon(icon-name="chevron-left")
                span._back-btn-text {{ locale("back") }}
            button._back-btn(
                type="button",
                role="button",
                @click="backToChooseCompareType",
                v-else
            )
                svg-icon._back-icon(icon-name="chevron-left")
                span._back-btn-text {{ locale("another-type") }}
            ._service-name(v-if="service.name") {{ service.name }}

        ._type
            ._type-ctrl(
                :class="{ 'compare-with-photo__type-ctrl_active': type === 'friends' }",
                v-if="type === 'friends' || type === ''"
            )
                ._type-ctrl-icon-box(@click="changeType")
                    ._type-ctrl-icon-box-wrap
                        svg-icon._type-ctrl-icon(icon-name="list")
                ._type-ctrl-name(@click="changeType") {{ locale("choose-in-list") }}
                    svg-icon._type-ctrl-chevron-down(icon-name="chevron-down")
                ._friends
                    input._friends-search(
                        v-if="friends.length > 0",
                        @input="searchFriend",
                        v-model="term",
                        type="text",
                        :placeholder="locale('searchFriends')"
                    )

                    perfect-scrollbar._friends-list(infinite-wrapper)
                        Loading(v-if="this.searchTimeout")
                        invite-friends._no-friends(
                            v-else-if="isInit && !friends.length",
                            :text="locale('noFriends')"
                        )
                        invite-friends._no-friends(
                            v-else-if="isInit && !friendsToSearch.length",
                            :text="locale('invite')"
                        )
                        ._friend(
                            v-for="(friend, index) in friendsToSearch",
                            v-bind:key="index",
                            @click="getCompare(friend)"
                        )
                            ._friend-avatar-box
                                img._avatar(:src="friend.personAvatar", v-if="friend.personAvatar")
                                ._avatar.-no-avatar(v-else)
                                    svg-icon._no-avatar-icon(icon-name="upload-photo")
                            ._friend-name {{ friend.personName }}
                infinite-loading(@infinite="infiniteHandler")
                    ._more-users(slot="spinner") {{ locale("loading") }}
                    ._more-users(slot="no-more") {{ locale("no") }}
                    ._more-users(slot="no-results") {{ locale("noFriendsShort") }}
            ._type-ctrl(@click="type = 'photo'", v-if="type === ''")
                ._type-ctrl-icon-box
                    ._type-ctrl-icon-box-wrap
                        svg-icon._type-ctrl-icon(icon-name="upload-photo")
                ._type-ctrl-name {{ locale("upload-friend-photo") }}
            choose-friend-photos(v-if="type === 'photo'", :type="service")
</template>

<script>
import { friendMixins } from "../../../mixins/friendMixins";
import InfiniteLoading from "vue-infinite-loading";
import ChooseFriendPhotos from "./choose-firend-photos/choose-friend-photos";
import { compareMixins } from "@/mixins/compare.mixins";
import InviteFriends from "@/components/person/change-photos/invite-friends/invite-friends.vue";

export default {
    name: "CompareWithPhoto",
    components: {
        ChooseFriendPhotos,
        InfiniteLoading,
        InviteFriends,
    },
    mixins: [friendMixins, compareMixins],
    props: {
        service: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        isInit: false,
        term: "",
        searchTimeout: null,
        friends: [],
        friendsToSearch: null,
        friendsTotal: 0,
        filter: {
            gender: "ALL",
            age: "ALL",
            sort: "DATE",
            compare: "ALL",
            term: "",
        },
        person: "",
        page: 1,
        compareType: "",
        type: "",
        taskInterval: null,
    }),
    computed: {
        // Для покупки
        activeType: (state) => state.service,
        personName: (state) => state.person.personName,
        personUuid: (state) => state.person.personId,
    },
    watch: {
        type(val) {
            if (val === "friends") {
                this.getFriends();
            }
        },
    },
    created() {
        this.friendsCount().then((response) => {
            console.log("friendsCount", response.data);
            this.friendsTotal = response.data.friendsCount;
        });
        this.friendsToSearch = this.friends;
    },
    methods: {
        changeType() {
            if (this.type === "friends") {
                this.type = "";
                return;
            }

            this.type = "friends";
        },
        searchFriend() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;

            this.searchTimeout = setTimeout(() => {
                this.friendsToSearch = this.friends.filter((el) =>
                    el.personName
                        .toLowerCase()
                        .trim()
                        .includes(this.term.toLowerCase().trim()),
                );

                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }, 1000);
        },
        backToPersonDescription() {
            this.$bus.$emit("backToPersonDescription");
        },
        backToChooseCompareType() {
            this.type = "";
        },
        infiniteHandler($state) {
            this.friendsCount().then((response) => {
                this.friendsTotal = response.data.friendsCount;
            });
            this.getFriends($state);
        },
        async getFriends($state) {
            const itemsPerPage = 10;
            await this.getPersons(
                "friends",
                this.page,
                itemsPerPage,
                this.filter,
            )
                .then((response) => {
                    if (response.data.friendList.length) {
                        this.page += 1;
                        response.data.friendList.forEach((friend, idx, arr) => {
                            if (idx == 0) {
                                this.friends.push(friend);
                            }
                            if (
                                idx > 0 &&
                                friend.userId != arr[idx - 1].userId
                            ) {
                                this.friends.push(friend);
                            }
                        });

                        if ($state) {
                            if (this.friendsTotal <= itemsPerPage)
                                $state.complete();
                        }
                    }
                    if ($state) {
                        $state.complete();
                    }
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("error"),
                        this.localeError("friendListNotReceived"),
                    );
                });
            this.isInit = true;
        },
        async getCompare(friend) {
            if (friend.personId) {
                this.person = friend;
            }
            // Если нет хотя бы одной фотографии - говорим об этом
            if (!this.myPhotos.profile || !this.myPhotos.anfas) {
                this.showUploadPhotosAttempt();
                return;
            }

            await this.getCompareResult(this.personUuid, this.service.id)
                .then((r) => {
                    const result = r.data.compareWithPersonGet,
                        percent = result.resultValue,
                        title = result.resultTitle,
                        desc = result.resultDescription;

                    if (!percent) {
                        this.offerToBuyCompare(this.service.id);
                        return;
                    }

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: this.service,
                            photo: friend.personAvatar,
                            personUuid: friend.personUuid,
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "compare-with-photo";
</style>
